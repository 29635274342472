'use strict';

// Polyfills
require('document-register-element');

// Smartbanner
import 'smartbanner.js/dist/smartbanner.js';

// Vue imports
import Vue from 'vue';
import Router from 'vue-router';

// Home page import
import App from './App.vue';

// Async page imports
const ClosedEvent = () => import('pages/events/ClosedEvent.vue');
const DemoLogin = () => import('pages/DemoLogin.vue');
const Landing = () => import('pages/Landing.vue');
const OpenEvent = () => import('pages/events/OpenEvent.vue');
const Privacy = () => import('pages/Privacy.vue');
const Terms = () => import('pages/Terms.vue');

Vue.use(Router);

const router = new Router({ mode: 'history', routes: [
  { path: '/', name: 'landing', component: Landing },
  { path: '/demo-login', name: 'demo-login', component: DemoLogin },
  { path: '/privacy', name: 'privacy', component: Privacy },
  { path: '/terms', name: 'terms', component: Terms },
  {
    path: '/event/:referenceToken',
    alias: ['/hangout/:referenceToken', '/invites/:referenceToken'],
    name: 'closed-event',
    component: ClosedEvent,
    props: true,
  },
  {
    path: '/events/:shareToken',
    name: 'open-event',
    component: OpenEvent,
    props: true,
  },
]});

document.addEventListener('DOMContentLoaded', function () {
  new Vue({ el: '#app', router, render: (h) => h(App) });
});
